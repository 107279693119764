
.one {
    font-size: 0.7em;
    width: 230px;
    margin-top: 2.5%;
    border-radius: 15px;
    margin-left: 20%;
}

.four {
    font-size: 0.7em;
    width: 252px;
    margin-top: 2.5%;
    border-radius: 15px;
    margin-left: 20%;
}

@media (max-width: 991.98px) {
    .one {
        font-size: 0.7em;
        width: 230px;
        margin-top: 2.5%;
        border-radius: 15px;
        margin-left: 10px;
    }

    .three {
        font-size: 0.7em;
        width: 230px;
        margin-top: 2.5%;
        border-radius: 15px;
        margin-left: 10px;
    }

    .four {
        font-size: 0.7em;
        width: 252px;
        margin-top: 2.5%;
        border-radius: 15px;
        margin-left: 10px;
    }
}

h3 {
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    color: white;
}

h4 {
    color: #501f84;
    font-weight: bold;
    font-size: 25px;
}

.aboutp {
    font-weight: normal;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}





.headanim::before {
    content: "Boltzmann Labs is a pioneer in AI Drug Discovery";
    animation: animate infinite 15s;
    padding-left: 10px;
    animation-delay: 4s;
    color: #501f84;
}

@keyframes animate {
    0% {
        content: "Aimed at setting up a powerhouse of Drug Discovery by developing potential tools and ecosystems";
        animation-delay: 3s;
    }

    50% {
        content: "Research accelerated in all stages of Drug Discovery using the latest technology and data-driven approaches.";
        animation-delay: 3s;
    }

    100% {
        content: "Be it small molecules, peptides, or proteins Boltzmann has solutions for all kinds of drug discovery problems.";
        animation-delay: 3s;
    }
}

#policy:hover {
    color: white;
}

.app a {
    color: #501f84;
    font-size: 14px;
    font-weight: bold;
}

.app a:hover {
    color: #501f84;

}