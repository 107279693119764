.body {
  background-color: white;
  margin: 0px
}

.block-number {
  text-align: center;
  font-weight: bold;
  font-size: 0.8125em;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #191919;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}

.block-number:hover {
  opacity: 1;
}

.block-number::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 6px;
  margin-bottom: 2px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #191919;
}

.block-number a:hover,
.block-number a:focus {
  color: #191919;
}

.main-content {
  background-color: white;
}

.footer {
  background-color: white;
  border-top: 1px solid #f1f2f3;
}

.demo-section .main-content .section,
.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar,
.demo-header .main-content .header,
.demo-footer .main-content .footer {
  margin: 30px 20px 100px;
  border: 1px solid #f5f6f7;
}

.demo-topbar .main-content .topbar,
.demo-navbar .main-content .navbar {
  position: static;
  margin: 0;
}

.sample-blocks img {
  border-radius: 0.25rem;
}
.aboutp {
  color: black;
  font-weight: normal;

  font-size: 20px;

  font-family: Arial, Helvetica, sans-serif;

}



.sample-blocks img.aos-animate {
  opacity: 0.5 !important;
}

.sample-blocks img:hover {
  opacity: 1 !important;
  -webkit-transition: 0.7s !important;
  transition: 0.7s !important;
}

.sample-blocks a {
  position: absolute;
}

.sample-blocks a:nth-child(1) {
  -webkit-animation: wobble 4s infinite;
  animation: wobble 4s infinite;
  right: 15px;
  bottom: -20px;
}

.sample-blocks a:nth-child(2) {
  -webkit-animation: wobble 8s infinite;
  animation: wobble 8s infinite;
  right: 320px;
  bottom: 30px;
}

.sample-blocks a:nth-child(3) {
  -webkit-animation: wobble 5s infinite;
  animation: wobble 5s infinite;
  right: 0px;
  bottom: 190px;
}

.sample-blocks a:nth-child(4) {
  -webkit-animation: wobble 6s infinite;
  animation: wobble 6s infinite;
  right: 280px;
  bottom: 180px;
}

.sample-blocks a:nth-child(5) {
  -webkit-animation: wobble 9s infinite;
  animation: wobble 9s infinite;
  right: 270px;
  bottom: 320px;
}

.sample-blocks a:nth-child(6) {
  -webkit-animation: wobble 7s infinite;
  animation: wobble 7s infinite;
  right: 20px;
  bottom: 370px;
}

.sample-blocks a:nth-child(7) {
  -webkit-animation: wobble 5s infinite;
  animation: wobble 5s infinite;
  right: 200px;
  bottom: 470px;
}

.sample-blocks a:nth-child(8) {
  -webkit-animation: wobble 8s infinite;
  animation: wobble 8s infinite;
  right: 10px;
  bottom: 470px;
}

.sample-blocks a:nth-child(9) {
  -webkit-animation: wobble 9s infinite;
  animation: wobble 9s infinite;
  right: 0px;
  bottom: 570px;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}

@media (max-width: 400px) {

  body>.footer .social a:last-child,
  body>.footer .nav a:last-child {
    display: none;
  }
}

/*# sourceMappingURL=style.css.map */

/* header */
.header {
  background-color: white;
  height: 100%;
}

.navbar-expand-lg.stick {
  background-color: white;
}

.navbar {
  background-color: white;
}




.nav-link {
  color: black;
}

.nav-link:hover {
  color: gold;
}

.navbar-mobile {
  background-color: white;
}

.navbar-mobile .nav-link {
  color: black;
}

.navbar-mobile .nav-link:hover {
  color: black;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-mobile {
    flex-direction: column;
    align-items: flex-start;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -300px;
    bottom: 0;
    padding: 20px;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
    transition: left 0.3s ease;
    z-index: 1002;
  }

  .navbar-expand-lg .navbar-mobile .nav-navbar .nav-link {
    color: black;
  }

  .navbar-expand-lg .navbar-mobile .nav-navbar .nav-link:hover {
    color: yellow;
  }

  .navbar-expand-lg .navbar-left .navbar-toggler .navbar-toggler-icon {
    stroke: #501F84;

  }

  .icon-red {
    color: green;
  }

}

.section {
  background-color: #501F84;
}

.p-1 {
  color: white;
  font-weight: 600;
}

.navbar-scrolled [data-navbar="fixed"] {
  background-color: rgba(255, 255, 255, 0.99);
  box-shadow: 0 1px 9px rgb(0 0 0 / 5%);
}

/* product carousel buttons */
.boltchem-button {

  font-size: 0.7em;
  width: 230px;
  border-radius: 15px;
  border: 1px solid white;
  color: white;
  margin-top: 5px;
}

.boltchem-button:hover {
  border: 1px solid white;
  color: white;
  background-color: #501F84;
}

.section-button {
  font-size: 0.7em;
  width: inherit;
  border-radius: 15px;
  border: 1px solid #501F84;
  color: #501F84;
  margin-top: 5px;
}

.section-button:hover {
  background-color: white;
  border: 1px solid #501F84;
  color: #501F84;
  box-shadow: none;
}



/* producct sub heading */
.product {
  font-size: 30px;
  line-height: 130%;
  font-weight: 600;
  color: black;
}

.psub-head {
  color: #501F84;
  margin-left: 2%;
}

.psub-head2 {
  color: #501F84;
}

.psub-p {
  color: black;
  font-weight: 600;
  margin-left: 2%;
}

.psub-p2 {
  color: black;
  font-weight: 600;
}


/* button styling */
.navbar-toggler-icon {
  color: #501F84;
}

/* contact */
.contact-container {
  background-color: #501F84;
  color: white;
  border-radius: 20px;
}


.contact-button {
  border: 0px;
  background-color: #501F84;
  color: white;
}

.contact-button:hover {
  background-color: #2dce4b;
  color: white;
}

.mail-button {
  border: 2px solid white;
  color: white;
  background-color: inherit;
}

.mail-button:hover {
  border: 2px solid yellow;
  color: yellow;
  background-color: inherit;
}

.timeline-section {
  height: 25vh;
  border: 1px solid #501F84;
}

.work-process .card {
  background: #501F84;
  color: white;
  min-height: 180px;
}

.work-process .card .card-title {
  font-weight: bold;
  text-align: center;
  color: yellow;
}

.work-process .card .card-text {
  font-weight: normal;
  text-align: center;
  color: #c3bdbd;
}

.work-process {
  padding: 40px 20px 30px 20px;
}

/* video background */
#background-video {
  height: inherit;
  width: inherit;
  object-fit: contain;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.video-container {
  position: absolute;
  top: 0;
  height: inherit;
  bottom: 0;
  width: 100%;
  padding: 0;
}

.video-container video {
  /* Make video to at least 100% wide and tall */
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#text {
  font-family: monospace;
  opacity: 1;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .video-container video {
    /* Make video to at least 100% wide and tall */
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 200;
    height: 300;
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header{
    padding-bottom: 80%;
    background: #501F84;
  }
}

.overlay {
  background-color: white;
  opacity: 0.6;
  width: inherit;
  height: inherit;
  padding: 15% 25px;
  line-height: 1.5;
  text-align: center;
}

.overlay :last-child {
  margin-bottom: 0;
}

.overlay h1 {
  font-size: 32pt;
  font-weight: bold;
  margin: 0 0 1em;
  color: #501F84;
}

.overlay p {
  font-size: 11pt;
  text-shadow: 0 0 0.3em #000;
  margin: 1em 0;
}

.overlay a {
  color: #fff;
}

code {
  font-family: monospace;
}

.hero-section {
  height: 100vh;
  display: flex;
  align-items: center;
}

.hero-section .hero-content {
  height: 70vh;
  border: 2px solid red;
  background-color: white;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
}


/* kjhgjhgjhg */
#headline {
  font-size: 30px;
  color: #501F84;
}

.w300 {
  width: 310px;
}

.cmt {
  margin-top: -97px;
}

.head-card {
  background-color:transparent;
  border-radius: 0%;
}

.slideTitle {
  text-align: center;
  color: gold;
  font-weight: bold;
  font-size: 25px;
  font-size: 30px;
}


.navbar-brand img {
  max-height: 60px;
}

@media only screen and (max-width:400px) {
  .special-image {
    margin-top: 74px;
    margin-left: -224px;
  }
}


/* mission and vision */
.vision-row {
  background-color: #501f84;
  margin: 15px
}

.vision-title {
  color: yellow;
  font-size: 25px;
  font-weight: 600
}

.vision-para {
  color: white;
  font-weight: normal;
  font-size: 22px;
  line-height: 140%;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}

.vision-flex {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.vision-flex .vision-images {
  width: 180px;
  height: 180px;
  display: block;
  margin: auto;
}

.team-lead {
  display: block;
  width: 70vw;
  margin: auto;
}

.team-lead2 li {
  display: block;
  margin: auto;
}

.work-flow-head {
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 8%, rgba(137, 97, 168, 1) 26%, rgba(88, 31, 132, 1) 51%, rgba(111, 61, 149, 1) 77%, rgba(255, 255, 255, 1) 92%);

}

@media only screen and (max-width: 768px) {
  .team-lead {
    display: block;
    margin: auto;
  }

  .work-flow-head {
    background: #501F84;
  }
}

.hero-card {
  color: white;
  background-color: rgb(67 25 112);
  font-size: 18px;
  text-align: center;
  border-radius: 0;
  border-left: 1px solid white;
  border-bottom-right-radius:50px;
  border-top-right-radius:50px;
}

.custom-popover {
  --bs-popover-border-color: #501F84;
  --bs-popover-header-bg: #2a8500;
  --bs-popover-header-color: var(--bs-white);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: .5rem;
}

video {
  padding: 0px
}

.partner-text {
  text-align: justify;
  color: grey;
  font-weight: 500;
  margin-top: 2%;
  line-height: 30px;
  font-size: 20px;
}.partner-text2 {
  text-align: justify;
  color: grey;
  font-weight: 500;
  margin-top: 2%;
  line-height: 30px;
  font-size: 18px;
}

/* new cards */
.team-bg {
	background-color: #2a8500;
	overflow: hidden;
}
.team .section-title {
	padding-bottom: 40px;
}
.team .section-title .main-team-subheading {
	font-size: 14px;
	font-weight: 500;
	padding: 0;
	line-height: 1px;
	margin: 0 0 5px 0;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #aaaaaa;
}
.team .section-title .main-team-subheading::after {
	content: "";
	width: 120px;
	height: 1px;
	display: inline-block;
	background: #556270;
	margin: 4px 10px;
}
.team .main-team-heading {
	margin: 0;
	font-size: 36px;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
}
.team .member {
	position: relative;
	box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
	padding: 30px;
	border-radius: 4px;
	background: #fff;
}
.team .member .pic {
	overflow: hidden;
	width: 900px;
	border-radius: 4px;
}
.team .member .pic img {
	transition: ease-in-out 0.3s;
}
.team .member:hover img {
transform: scale(1.1);
}
.team .member .member-info {
	padding-left: 30px;
}
.team .member .member-heading {
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 20px;
	color: #2a8500;
	line-height: 1.2;
}
.team .member span {
	display: block;
	font-size: 15px;
	padding-bottom: 10px;
	position: relative;
	font-weight: 500;
}
.team .member span::after {
	content: '';
	position: absolute;
	display: block;
	width: 50px;
	height: 1px;
	background: #dee2e6;
	bottom: 0;
	left: 0;
}
.team .member .member-para {
	margin: 10px 0 0 0;
	font-size: 14px;
}
.team .member .social {
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.team .member .social a {
	transition: ease-in-out 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	background: #8795a4;
}
.team .member .social a + a {
margin-left: 8px;
}
.team .member .social a:hover {
	background: #41A1FD;
}
.team .member .social a .team-icon {
	color: #fff;
	font-size: 16px;
	margin: 0 2px;
}


/* new cards */
.team-bg {
	background-color: #2a8500;
	overflow: hidden;
}
.team .section-title {
	padding-bottom: 40px;
}
.team .section-title .main-team-subheading {
	font-size: 14px;
	font-weight: 500;
	padding: 0;
	line-height: 1px;
	margin: 0 0 5px 0;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #aaaaaa;
}
.team .section-title .main-team-subheading::after {
	content: "";
	width: 120px;
	height: 1px;
	display: inline-block;
	background: #556270;
	margin: 4px 10px;
}
.team .main-team-heading {
	margin: 0;
	font-size: 36px;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
}
.team .member {
	position: relative;
	box-shadow: 0px 2px 15px rgba(85, 98, 112, 0.08);
	padding: 30px;
	border-radius: 4px;
	background: #fff;
}
.team .member .pic {
	overflow: hidden;
	width: 900px;
	border-radius: 4px;
}
.team .member .pic img {
	transition: ease-in-out 0.3s;
}
.team .member:hover img {
transform: scale(1.1);
}
.team .member .member-info {
	padding-left: 30px;
}
.team .member .member-heading {
	font-weight: 700;
	margin-bottom: 5px;
	font-size: 20px;
	color: #2a8500;
	line-height: 1.2;
}
.team .member span {
	display: block;
	font-size: 15px;
	padding-bottom: 10px;
	position: relative;
	font-weight: 500;
}
.team .member span::after {
	content: '';
	position: absolute;
	display: block;
	width: 50px;
	height: 1px;
	background: #dee2e6;
	bottom: 0;
	left: 0;
}
.team .member .member-para {
	margin: 10px 0 0 0;
	font-size: 14px;
}
.team .member .social {
	margin-top: 12px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.team .member .social a {
	transition: ease-in-out 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	background: #8795a4;
}
.team .member .social a + a {
margin-left: 8px;
}
.team .member .social a:hover {
	background: #41A1FD;
}
.team .member .social a .team-icon {
	color: #fff;
	font-size: 16px;
	margin: 0 2px;
}

/* new teams */
.row.heading h2 {
  color: #fff;
  font-size: 24.52px;
  line-height: 80px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 40px;
  padding-bottom: 20px;
  text-transform: uppercase;
}
ul{
margin:0;
padding:0;
list-style:none;
}
.heading.heading-icon {
  display: block;
}
.padding-lg {
display: block;
padding-top: 30px;
padding-bottom: 30px;
}
.practice-area.padding-lg {
  padding-bottom: 55px;
  padding-top: 55px;
}
.practice-area .inner{ 
   border:1px solid #999999; 
 text-align:center; 
 margin-bottom:28px; 
 padding:40px 25px;
}
.our-webcoderskull .cnt-block:hover {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  border: 0;
}
.practice-area .inner h3{ 
  color:#3c3c3c; 
font-size:24px; 
font-weight:350;
font-family: 'Poppins', sans-serif;
padding: 10px 0;
}
.practice-area .inner p{ 
  font-size:14px; 
line-height:22px; 
font-weight:350;
}
.practice-area .inner img{
display:inline-block;
}


.our-webcoderskull{
  background-color: #501F84;
  margin-top: 80px;

}
.our-webcoderskull .cnt-block{ 
 float:left; 
 width:100%; 
 background:#fff; 
 padding:30px 20px; 
 text-align:center; 
 border:2px solid #d5d5d5;
 margin: 0 0 28px;
}
.our-webcoderskull .cnt-block figure{
 width:148px; 
 height:148px; 
 border-radius:100%; 
 display:inline-block;
 margin-bottom: 15px;
}
.our-webcoderskull .cnt-block img{ 
 width:148px; 
 height:148px; 
 border-radius:100%; 
}
.our-webcoderskull .cnt-block h3{ 
 color:#2a2a2a; 
 font-size:20px; 
 font-weight:500; 
 padding:6px 0;
 text-transform:uppercase;
}
.our-webcoderskull .cnt-block h3 a{
text-decoration:none;
color:#2a2a2a;
}
.our-webcoderskull .cnt-block h3 a:hover{
color:#337ab7;
}
.our-webcoderskull .cnt-block p{ 
 color:#2a2a2a; 
 font-size:13px; 
 line-height:10px; 
 font-weight:400;
}
.our-webcoderskull .cnt-block .follow-us{
margin:20px 0 0;
}
.our-webcoderskull .cnt-block .follow-us li{ 
  display:inline-block; 
width:auto; 
margin:0 5px;
}
.our-webcoderskull .cnt-block .follow-us li .fa{ 
 font-size:24px; 
 color:#767676;
}
.our-webcoderskull .cnt-block .follow-us li .fa:hover{ 
 color:#025a8e;
}


#advisors{
  background-color: white;
}
#advisors h2{
color: #2a8500;
}

/* tooltip contaier */
.tooltiper {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltiper .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
   top: 100%;
  left: 0%;
  margin-left: -60px;
}

.tooltiper:hover .tooltiptext {
  visibility: visible;
}

.custom-popover {
  --bs-popover-border-color: #2a852a;
  --bs-popover-header-bg:#2a8500;
  --bs-popover-header-color: var(--bs-white);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: .5rem;
}


/* team card */
#t-cardb .t-card{
  background-color: #2a8500;
  border: 0px;
 

}
#t-cardb .t-card h3,#t-cardb .t-card p{
color: white;
}

.bh1{
  font-size: 2.1em;
  color: white;
}

.rotate {
  animation: rotation 10s infinite linear;
  position: absolute;
  top: 25%;
  left: 10%;
}
.rotate2{
  animation: rotation2 10s infinite linear;
  position: absolute;
  top: 35%;
  left: 10%;
}
.heroimage{
  position: relative;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


.revolute{
  animation: revolute,10s,linear;
}
 

@keyframes revolute {
  from{
    transform: translate(10,20);
  }
  to{
    transform:translate(200,300)
    }
  }


@keyframes rotation2 {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.header{
  padding-top: 80px;
}
.media {
  display: flex;
}

.blog-sidebar .widget.widget_recent_post .post_item .media-body {
  padding-left: 20px;
  align-self: center;
  flex: 1;
}