.logo-light{width: 90%;}

.black{color: black;}

.boldText{

    color: #501f84;

    font-weight: bold;

}

.themeColor{

    color: #501f84;

}
.nav-link{
    text-align: left;
}