h3 {

    text-align: center;

    color: #501f84;

    font-weight: bold;

    font-size: 35px;

  }

.custom-accordion-item {
  background-color: #501f84 !important;
  color: white !important;
  border: none !important;
}

.custom-accordion-header {
  background-color: #501f84 !important;
  color: white !important;
  border: none !important;
}

.custom-accordion-body {
  background-color: #501f84 !important;
  color: white !important;
}


  h4 {

    text-decoration: underline;

    color: #501f84;

    font-weight: bold;

    font-size: 25px;

  }


  .aboutp {

    font-weight: normal;

    font-size: 17px;

    font-family: Arial, Helvetica, sans-serif;

  }

  

  .section-partnership {
    background-color: white;
  }

  .venture-button1 {
    border: 1px solid #501f84;
    color: #501f84;
    margin-top: 4%;
    width: 80%;
    margin-left: 4.5%;
  }

  .venture-button2 {
    border: 1px solid #501f84;
    color: #501f84;
    margin-top: 4%;
    width: 80%;
    margin-right: 1%;
    margin-left: 3%;
  }
  .venture-button1:hover,.venture-button2:hover{
    background-color: #501f84;
    color: white;
  }

  .accordion-button{
    background: none;
    border: none;
    color: white;
    font-size: 22px;
  }