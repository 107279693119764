 .homeblog {
    display: 'flex';
    justify-content : 'space-around';
    padding: 10px;
    margin-left: 10px

 }
 .post{
    max-width: 25%;
 }
 @media screen and (max-width:500px){
    .homeblog{
        flex-direction: column;
        margin-left: 0;
        padding-left: 20px;
        flex-wrap: wrap;
        align-content: center;
    }
    .post{
        max-width: 80%;
    }
 }