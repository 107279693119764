.main-timeline{ position: relative; }
.main-timeline:before,
.main-timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
}
.main-timeline:before{
    content: "";
    width: 3px;
    height: 95%;
    background:#501F84;
    position: absolute;
    top: 0;
    left: 50%;
}
.fleft{
    color: black;
    float: left;
    font-size: 21px;
    text-align: left;
    font-weight: 900;
}
.left{
    float: left;
}
.main-timeline .timeline{
    width: 50%;
    float: left;
    position: relative;
    z-index: 1;
}
/* .main-timeline .timeline:before,
.main-timeline .timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
} */
.timeline::before{
display: none;
}
.main-timeline .timeline:first-child:before,
.main-timeline .timeline:last-child:before{
    content: "";
    width: 25px;
    visibility: hidden;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid rgba(211,207,205,1);
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 1;
}
.main-timeline .timeline:last-child:before{
    top: auto;
    bottom: 0;
}
.main-timeline .timeline:last-child:nth-child(even):before{
    right: auto;
    left: -12px;
    bottom: -2px;
}
.main-timeline .timeline-content{
    text-align: center;
    margin-top: 8px;
    position: relative;
    transition: all 0.3s ease 0s;
}
.main-timeline .timeline-content:before{
    content: "";
    width: 100%;
    height: 5px;
    background: #501F84;
    position: absolute;
    top: 88px;
    left: 0;
    z-index: -1;
}
.main-timeline .circle{
    width: 180px;
    height: 180px;
    border-radius: 50%;
    background: #fff;
    border: 8px solid rgb(80 31 132);
    float: left;
    margin-right: 25px;
    position: relative;
}
.main-timeline .circle:before{
    content: "";
    width: 26px;
    height: 30px;
    background:#501F84;
    margin: auto;
    position: absolute;
    top: 0;
    right: -33px;
    bottom: 0;
    z-index: -1;
    box-shadow: inset 7px 0 9px -7px #444;
}
.main-timeline .circle span{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    line-height: 268px;
    font-size: 80px;
    color: #454344;
}
.main-timeline .circle span:before,
.main-timeline .circle span:after{
    content: "";
    width: 28px;
    height: 50px;
    background: #fff;
    border-radius: 0 0 0 21px;
    margin: auto;
    position: absolute;
    top: -54px;
    right: -33px;
    bottom: 0;
    z-index: -1;
}
.main-timeline .circle span:after{
    border-radius: 21px 0 0 0;
    top: 0;
    bottom: -56px;
}
.main-timeline .circle .img{
    vertical-align: initial;
    border-radius: 50%;
    width: 85%;
}
.main-timeline .content{
    display: table;
    padding-right: 40px;
    position: relative;
    
}
.main-timeline .year{
    display: block;
    padding:37px 0px 37px 0px;
    margin: 10px 0 13px 0;
    border-radius: 7px;
    font-size: 25px;
    font-weight: bold;
    color: rgb(80 31 132);
}
.main-timeline .title{
    font-size: 25px;
    font-weight: bold;
    color: rgba(211,207,205,1);
    margin-top: 0;
}
.main-timeline .description{
    font-size: 14px;
    color: #333;
    text-align: justify;
}
.main-timeline .icon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    border: 4px solid black;
    position: absolute;
    top: 78px;
    right: -14px;
}
.main-timeline .icon:before{
    content: "";
    width: 15px;
    height: 25px;
    background:black;
    margin: auto;
    position: absolute;
    top: -1px;
    left: -15px;
    bottom: 0;
    z-index: -1;
}
.main-timeline .icon span:before,
.main-timeline .icon span:after{
    content: "";
    width: 21px;
    height: 25px;
    background: #fff;
    border-radius: 0 0 21px 0;
    margin: auto;
    position: absolute;
    top: -30px;
    left: -15px;
    bottom: 0;
    z-index: -1;
}
.main-timeline .icon span:after{
    border-radius: 0 21px 0 0;
    top: 0;
    left: -15px;
    bottom: -30px;
}
.main-timeline .timeline:nth-child(2n) .timeline-content,
.main-timeline .timeline:nth-child(2n) .circle{
    float: right;
}
.main-timeline .timeline:nth-child(2n) .circle{
    margin: 0 0 0 25px;
}
.main-timeline .timeline:nth-child(2n) .circle:before{
    right: auto;
    left: -33px;
    box-shadow: -7px 0 9px -7px #444 inset;
}
.main-timeline .timeline:nth-child(2n) .circle span:before,
.main-timeline .timeline:nth-child(2n) .circle span:after{
    right: auto;
    left: -33px;
    border-radius: 0 0 21px 0;
}
.main-timeline .timeline:nth-child(2n) .circle span:after{
    border-radius: 0 21px 0 0;
}
.main-timeline .timeline:nth-child(2n) .content{
    padding: 0 0 0 40px;
    margin-left: 2px;
}
.main-timeline .timeline:nth-child(2n) .icon{
    right: auto;
    left: -14px;
}
.main-timeline .timeline:nth-child(2n) .icon:before,
.main-timeline .timeline:nth-child(2n) .icon span:before,
.main-timeline .timeline:nth-child(2n) .icon span:after{
    left: auto;
    right: -15px;
}
.main-timeline .timeline:nth-child(2n) .icon span:before{
    border-radius: 0 0 0 21px;
}
.main-timeline .timeline:nth-child(2n) .icon span:after{
    border-radius: 21px 0 0 0;
}
.main-timeline .timeline:nth-child(2){
    margin-top: 80px;
}
.main-timeline .timeline:nth-child(odd){
    margin: -175px 0 0 0;
}
.main-timeline .timeline:nth-child(even){
    margin-bottom: 180px;
}
.main-timeline .timeline:first-child,
.main-timeline .timeline:last-child:nth-child(even){
    margin: 0;
}
@media only screen and (max-width: 990px){

    .mobileBolt{
        margin-top:-100px !important;
    }
    .main-timeline .year{
        margin:12px 0px !important;
    }
    .ag-timeline_title-box{
        margin-top: 10px;
        text-align: center;
        padding: 10px;
    }
    .main-timeline:before{ left: 100%; }
    .main-timeline .timeline{
        width: 100%;
        float: none;
        margin-bottom: 20px !important;
    }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before{
        left: auto !important;
        right: -13px !important;
    }
    .main-timeline .timeline:nth-child(2n) .circle{
        float: left;
        margin: 0 25px 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .circle:before{
        right: -33px;
        left: auto;
        box-shadow: 7px 0 9px -7px #444 inset;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:before,
    .main-timeline .timeline:nth-child(2n) .circle span:after{
        right: -33px;
        left: auto;
        border-radius: 0 0 0 21px;
    }
    .main-timeline .timeline:nth-child(2n) .circle span:after{
        border-radius: 21px 0 0 0;
    }
    .main-timeline .timeline:nth-child(2n) .content{
        padding: 0 40px 0 0;
        margin-left: 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon{
        right: -14px;
        left: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon:before,
    .main-timeline .timeline:nth-child(2n) .icon span:before,
    .main-timeline .timeline:nth-child(2n) .icon span:after{
        left: -15px;
        right: auto;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:before{
        border-radius: 0 0 21px 0;
    }
    .main-timeline .timeline:nth-child(2n) .icon span:after{
        border-radius: 0 21px 0 0;
    }
    .main-timeline .timeline:nth-child(2),
    .main-timeline .timeline:nth-child(odd),
    .main-timeline .timeline:nth-child(even){
        margin: 0;
    }
}
@media only screen and (max-width: 480px){
    .main-timeline .icon{
        top: -75.6%;
        right: -10%;
    }
    .main-timeline .icon2{
        top: -59.6%;
        right: -4%;
    }
    /* .main-timeline:before{ left: 0; }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before{
        left: -12px !important;
        right: auto !important;
    }
    .main-timeline .circle,
    .main-timeline .timeline:nth-child(2n) .circle{
        width: 130px;
        height: 130px;
        float: none;
        margin: 0 auto;
    }
    .main-timeline .timeline-content:before{
        width: 99.5%;
        top: 68px;
        left: 0.5%;
    }
    .main-timeline .circle span{
        line-height: 115px;
        font-size: 60px;
    }
    .main-timeline .circle:before,
    .main-timeline .circle span:before,
    .main-timeline .circle span:after,
    .main-timeline .icon{
        display: none;
    }
    .main-timeline .content,
    .main-timeline .timeline:nth-child(2n) .content{
        padding: 0 10px;
    }
    .main-timeline .year{
        margin-bottom: 0px;
    }
    .main-timeline .description{
        text-align: center;
    } */
}

