h3 {

    text-align: center;

    color: #501f84;

    font-weight: bold;

    font-size: 35px;

}



h4 {

    text-decoration: underline;

    color: #501f84;

    font-weight: bold;

    font-size: 25px;

}



.aboutp {

    font-weight: normal;

    font-size: 20px;

    font-family: Arial, Helvetica, sans-serif;

}

.section-contact {
    background-color: white;
}